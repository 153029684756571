import Turnstile from "react-turnstile";
import { useEffect, useState } from "react";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { isProduction } from "../../../../components/tools";

const JackTurnstile = ({ onVerify }) => {
  useEffect(() => {
    const { href } = window.location;
    const cleanUrl = href.includes("?") ? href.split("?")[0] : href;

    const isAutoVerify =
      !isProduction ||
      String(cleanUrl).includes("deploy-preview") ||
      String(cleanUrl).includes("internal"); // if its a deploy preview, ignore turnstile

    isAutoVerify && onVerify(isAutoVerify);
  }, []);

  if (!isProduction) return null;
  return (
    <div>
      <Turnstile
        size="normal"
        sitekey="0x4AAAAAAANMlwaJnK_kBHqU"
        onVerify={(verifyToken) => {
          onVerify(true, verifyToken);
        }}
        theme="light"
      />
    </div>
  );
};

export const TurnstilePage = ({ children, setVerifyToken = () => {} }) => {
  const [isVerified, setIsVerified] = useState(false);
  const isLocalhost = process.env.NODE_ENV === "development";
  
  if (!isVerified && isProduction)
    return (
      <div
        style={{ height: "100vh", background: "white", width: "100vw" }}
        className="d-flex justify-content-center align-items-center"
      >
        <div>
          <JackIcons name="jack-horizontal" style={{ marginBottom: 20 }} />
          <JackTurnstile
            onVerify={(val, verifyToken) => {
              setIsVerified(val);
              setVerifyToken(verifyToken);
            }}
          />
        </div>
      </div>
    );

  return children;
};
