import { jackColors } from "../../../assets/colors";
import { TextInlineMedium } from "../../../components/Text";

export const LoginInline = ({ onClick }) => (
  <TextInlineMedium
    className="hover"
    onClick={onClick}
    style={{
      textDecoration: "underline",
      marginLeft: 4,
      color: jackColors.black34,
    }}
  >
    Log in
  </TextInlineMedium>
);
