import { jackColors } from "../../../../../../../../assets/colors";
import { GothamRegular } from "../../../../../../../../components/Text";
import styles from "./styles.module.css";

const Ellipse = () => {
  return (
    <svg
      width="5"
      height="4"
      viewBox="0 0 5 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.23047" cy="2" r="2" fill="white" />
    </svg>
  );
};

const CopyRightIcon = () => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.27047 5.43C5.29547 5.265 5.35047 5.12 5.42047 4.995C5.49047 4.87 5.59047 4.765 5.71547 4.685C5.83547 4.61 5.98547 4.575 6.17047 4.57C6.28547 4.575 6.39047 4.595 6.48547 4.635C6.58547 4.68 6.67547 4.74 6.74547 4.815C6.81547 4.89 6.87047 4.98 6.91547 5.08C6.96047 5.18 6.98047 5.29 6.98547 5.4H7.88047C7.87047 5.165 7.82547 4.95 7.74047 4.755C7.65547 4.56 7.54047 4.39 7.39047 4.25C7.24047 4.11 7.06047 4 6.85047 3.92C6.64047 3.84 6.41047 3.805 6.15547 3.805C5.83047 3.805 5.54547 3.86 5.30547 3.975C5.06547 4.09 4.86547 4.24 4.70547 4.435C4.54547 4.63 4.42547 4.855 4.35047 5.115C4.27547 5.375 4.23047 5.645 4.23047 5.935V6.07C4.23047 6.36 4.27047 6.63 4.34547 6.89C4.42047 7.15 4.54047 7.375 4.70047 7.565C4.86047 7.755 5.06047 7.91 5.30047 8.02C5.54047 8.13 5.82547 8.19 6.15047 8.19C6.38547 8.19 6.60547 8.15 6.81047 8.075C7.01547 8 7.19547 7.895 7.35047 7.76C7.50547 7.625 7.63047 7.47 7.72047 7.29C7.81047 7.11 7.86547 6.92 7.87047 6.715H6.97547C6.97047 6.82 6.94547 6.915 6.90047 7.005C6.85547 7.095 6.79547 7.17 6.72047 7.235C6.64547 7.3 6.56047 7.35 6.46047 7.385C6.36547 7.42 6.26547 7.43 6.16047 7.435C5.98047 7.43 5.83047 7.395 5.71547 7.32C5.59047 7.24 5.49047 7.135 5.42047 7.01C5.35047 6.885 5.29547 6.735 5.27047 6.57C5.24547 6.405 5.23047 6.235 5.23047 6.07V5.935C5.23047 5.76 5.24547 5.595 5.27047 5.43ZM6.23047 1C3.47047 1 1.23047 3.24 1.23047 6C1.23047 8.76 3.47047 11 6.23047 11C8.99047 11 11.2305 8.76 11.2305 6C11.2305 3.24 8.99047 1 6.23047 1ZM6.23047 10C4.02547 10 2.23047 8.205 2.23047 6C2.23047 3.795 4.02547 2 6.23047 2C8.43547 2 10.2305 3.795 10.2305 6C10.2305 8.205 8.43547 10 6.23047 10Z"
        fill="white"
      />
    </svg>
  );
};

const FooterTags = () => {
  const handleClickTnC = () => {
    window.open("https://jackrevamp.netlify.app/tnc-login/?value=1", "_blank");
  };
  const handleClickPolicy = () => {
    window.open("https://jackrevamp.netlify.app/tnc-login/?value=2", "_blank");
  };

  return (
    <div className={styles.footerTagsContainer}>
      <div className={styles.copyright}>
        <GothamRegular
          woFontColor
          className="font12"
          style={{ color: jackColors.neutral100 }}
        >
          Copyright
        </GothamRegular>
        <CopyRightIcon />
        <GothamRegular
          woFontColor
          className="font12"
          style={{ color: jackColors.neutral100 }}
        >
          2024 Jack Inc.
        </GothamRegular>
      </div>
      <Ellipse />
      <GothamRegular
        woFontColor
        className="font12"
        style={{ color: jackColors.neutral100, cursor: "pointer" }}
        onClick={handleClickTnC}
      >
        Jack Service Agreement
      </GothamRegular>
      <Ellipse />
      <GothamRegular
        woFontColor
        className="font12"
        style={{ color: jackColors.neutral100, cursor: "pointer" }}
        onClick={handleClickPolicy}
      >
        Privacy Policy
      </GothamRegular>
    </div>
  );
};

export default FooterTags;
