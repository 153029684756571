import { useEffect } from "react";
import { passwordCheckerProps } from "../components";

export const ValidationChecker = ({ formWatch, setError }) => {
  const formWatchKey = Object.keys(formWatch);
  const passwordMatch = formWatch.password === formWatch.password_confirmation;

  setError({});

  const { isValid } = passwordCheckerProps(formWatch?.password);

  let errorKeysArr = [];
  formWatchKey.map((item) => {
    const value = !!formWatch[item];
    if (!value) {
      errorKeysArr.push(item);
      setError((prev) => ({
        ...prev,
        [item]: "Please fill out this field",
      }));
    }
  });

  if (!passwordMatch && !!formWatch.password_confirmation) {
    errorKeysArr.push("password_confirmation");
    setError((prev) => ({
      ...prev,
      password_confirmation: "Your passwords don't match",
    }));
  }

  if (!isValid) {
    setError((prev) => ({ ...prev, pass_template: true }));
    errorKeysArr.push("pass_template");
  }

  return errorKeysArr.length > 0;
};

export const ErrorWatcher = ({ formWatch, error }) => {
  const errorKeys = Object.keys(error);
  const watchName = formWatch?.name;
  const watchMobile = formWatch?.mobile;
  const watchEmail = formWatch?.email;
  const watchPass = formWatch?.password;
  const watchPassConfirm = formWatch?.password_confirmation;

  useEffect(() => {
    errorKeys.map((item) => {
      if (!!formWatch[item]) delete error[item];
    });
  }, [watchName, watchMobile, watchEmail, watchPass, watchPassConfirm]);
};
