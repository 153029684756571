import { useEffect, useState } from "react";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { Avatar } from "../../../components/Avatar";
import { Banner } from "../../../components/Banner";
import { GothamRegular, TextInlineMedium } from "../../../components/Text";
import { Layout } from "../components";
import styles from "../layout.module.css";
import { windowDimension } from "../../../components/tools";

export const CheckEmailComponent = ({
  setCurrentScreen = () => {},
  isSignedIn = false,
  title = "",
  subTitle = "",
  instruction = "",
  sendAgainInfo = "",
  sendAgainText = "",
  onSendAgain = () => {},
  iconName = "markunread_mailbox",
  woJumper,
}) => {
  const { isTabOrPhone: isResponsive } = windowDimension();
  const [timer, setTimer] = useState(0);
  const [isShowInfo, setIsShowInfo] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (timer) {
      const intervalId = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timer]);

  const afterSuccessSendAgain = () => {
    setIsSending(false);
    setTimer(60);
    setIsShowInfo(true);
  };

  const sendAgainHandler = async () => {
    setIsSending(true);
    onSendAgain(afterSuccessSendAgain);
  };

  return (
    <Layout
      woJumper={woJumper}
      isSignedIn={isSignedIn}
      title={title}
      icon={
        !isResponsive && (
          <Avatar
            size="extra-large"
            mainIcon={
              <JackIcons
                name={iconName}
                fill="white"
                style={{ width: 40, height: 40 }}
              />
            }
          />
        )
      }
      setCurrentScreen={setCurrentScreen}
    >
      <GothamRegular className={styles["grey-text"]} style={{ marginTop: -32 }}>
        {subTitle}
      </GothamRegular>
      <GothamRegular className={styles["grey-text"]}>
        {instruction}
      </GothamRegular>
      <GothamRegular className={styles["grey-text"]}>
        {sendAgainInfo}{" "}
        {timer ? (
          <TextInlineMedium className={styles["grey-text"]}>
            {sendAgainText} ({timer}s)
          </TextInlineMedium>
        ) : (
          <TextInlineMedium
            className={
              isSending ? styles["grey-text"] : styles["underline-medium"]
            }
            onClick={sendAgainHandler}
          >
            {sendAgainText}
          </TextInlineMedium>
        )}
      </GothamRegular>
      <Banner
        className={isShowInfo ? styles["visible"] : styles["hidden"]}
        msg="Email sent. Please check your inbox."
        msgStyle={{
          textAlign: "center",
          color: jackColors.black34,
          fontFamily: "GothamMedium",
        }}
      />
    </Layout>
  );
};
