import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { colors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { GothamRegular } from "../../../components/Text";
import { PhoneSelectionJack } from "../../../components/inputs/selection";
import {
  TextFieldJack,
  TextFieldJackPassword,
  TextFieldJackState,
} from "../../../components/inputs/textfield";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { apiBusiness, decryptToken } from "../../../tools/api";
import { Layout, PasswordChecker } from "../components";
import { LoginInline } from "../forgotPassword/inline";
import styles from "../layout.module.css";
import { ErrorWatcher, ValidationChecker } from "./checker";

const FirstStep = ({
  setCurrentScreen,
  setCurrentStep,
  payload,
  setPayload,
  isCreatePassword,
  onSubmit,
}) => {
  const {
    email: emailDefault,
    mobile: mobileDefault,
    mobile_country_iso: mobileIsoDefault,
    name: nameDefault,
    password: passwordDefault,
    password_confirmation: passwordConfirmationDefault,
  } = payload?.user || {};
  const { push, query } = useRouter();
  const {
    password: passwordQ,
    password_confirmation: password_confirmationQ,
    referral_code: referral_code,
  } = query;

  const defaultValues = query?.password
    ? {
        ...query,
        password: decryptToken(passwordQ),
        password_confirmation: decryptToken(password_confirmationQ),
      }
    : {
        name: nameDefault,
        email: emailDefault,
        mobile: mobileDefault,
        mobile_country_iso: mobileIsoDefault,
        password: passwordDefault,
        password_confirmation: passwordConfirmationDefault,
        referral_code: referral_code || "",
      };

  const { email } = query;
  const useFormObj = useForm({
    defaultValues,
  });

  const { errorSnackBar } = ToasterHook();

  const [error, setError] = useState({});
  const { watch, handleSubmit, setValue } = useFormObj;
  const passwordWatch = watch("password");

  const loginHandler = () => {
    push("/login");
  };

  useEffect(() => {
    if (email) return setValue("email", email);
  }, [email]);

  const defaultWatchers = [
    "name",
    "country_code",
    "mobile",
    "password",
    "password_confirmation",
    !isCreatePassword && "email",
  ].filter((item) => item);

  const formWatch = watch(defaultWatchers);

  ErrorWatcher({ formWatch, error });

  const errorFormatter = (error) => {
    const errorMessage = error?.response?.data?.error?.message || "";
    const isEmailNotExist =
      errorMessage.includes("40101") ||
      errorMessage.includes("Email is not registered in the database");
    const isEmailExist = errorMessage === "Invalid password";

    return { isEmailNotExist, isEmailExist };
  };

  const submit = async (data) => {
    const isError = ValidationChecker({ formWatch, setError });
    if (isError) return;
    if (onSubmit) return onSubmit(data);
    try {
      if (referral_code) {
        data.referral_code = referral_code;
      }
      const { email } = data || {};
      await apiBusiness.post("/authenticate", { email });
    } catch (err) {
      const { isEmailNotExist } = errorFormatter(err);

      if (isEmailNotExist) {
        const mobile_country_iso = data.country_code.value;
        data = { ...data, mobile_country_iso, is_pic_business: true };
        delete data.country_code;
        setPayload({ user: data });
        setCurrentStep && setCurrentStep(2);
        return;
      }
      errorSnackBar({ msg: "Email already exist" });
    }
  };

  const buttonStyle = {
    marginLeft: "auto",
    width: "40px",
    padding: 0,
  };

  const rightIcon = <JackIcons name="arrow-forward" fill={colors.neutral900} />;

  const password = (
    <>
      <TextFieldJackPassword
        useFormObj={useFormObj}
        label="Password"
        placeholder="Enter your password"
        name="password"
        error={error["password"]}
      />
      <PasswordChecker
        className={
          passwordWatch
            ? styles["password-checker-visible"]
            : styles["password-checker-hidden"]
        }
        isError={error?.pass_template}
        password={passwordWatch}
      />
      <TextFieldJackPassword
        useFormObj={useFormObj}
        label="Confirm Password"
        placeholder="Repeat your password"
        name="password_confirmation"
        error={error["password_confirmation"]}
      />
    </>
  );

  const mobile = (
    <div
      style={{
        display: "flex",
        gap: "16px",
      }}
    >
      <PhoneSelectionJack name="country_code" useFormObj={useFormObj} />
      <TextFieldJack
        name="mobile"
        label="Phone Number"
        placeholder="Enter your phone number"
        type="number"
        useFormObj={useFormObj}
        style={{ width: "100%" }}
        error={error["mobile"]}
      />
    </div>
  );

  return (
    <Layout
      title="Take the first step by creating your Jack account"
      setCurrentScreen={setCurrentScreen}
      woJumper={isCreatePassword}
    >
      <form className={styles["form"]} onSubmit={handleSubmit(submit)}>
        <TextFieldJack
          name="name"
          label="Full Legal Name"
          placeholder="Enter your full legal name"
          useFormObj={useFormObj}
          error={error["name"]}
        />
        {isCreatePassword ? (
          <TextFieldJackState
            value={email}
            label="Email"
            disabled
            helperText="This email will be used to complete your company registration process."
          />
        ) : (
          <TextFieldJack
            name="email"
            label="Email"
            placeholder="Enter your email"
            useFormObj={useFormObj}
            error={error["email"]}
          />
        )}
        {isCreatePassword ? (
          <>
            {password}
            {mobile}
          </>
        ) : (
          <>
            {mobile}
            {password}
          </>
        )}
        <ButtonJack
          // disabled={isButtonDisabled}
          rightIcon={rightIcon}
          style={buttonStyle}
        >
          Next
        </ButtonJack>
      </form>
      {!isCreatePassword && (
        <GothamRegular className={styles["grey-text"]}>
          Already have an account? <LoginInline onClick={loginHandler} />
        </GothamRegular>
      )}
    </Layout>
  );
};

export default FirstStep;
