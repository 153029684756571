import { Fragment } from "react";
import { jackColors } from "../../../../../../assets/colors";
import { Banner } from "../../../../../../components/Banner";
import { ButtonJack } from "../../../../../../components/ButtonsJack/parent";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../../../../components/Text";
import { PhoneSelectionJack } from "../../../../../../components/inputs/selection";
import { TextFieldJackState } from "../../../../../../components/inputs/textfield";
import { eventsTracker } from "../../../../../../universalFunctions/events";
import {
  DEMO_CLICK_GET_ACCESS,
  DEMO_CLICK_RESEND_ACCESS
} from "../../../../constants/events";

const RegisterForm = ({
  isDisabledSendEmail = false,
  formValues = {},
  countryCodeForm = {},
  registerDemo = () => {},
  setFormValues = () => {},
  goToResendForm = () => {},
}) => {
  return (
    <Fragment>
      <GothamMedium
        className="font24"
        style={{ color: jackColors.neutral900, textAlign: "center" }}
      >
        Verify your email and details first
      </GothamMedium>
      <Banner
        msg="Complete these details and verify your email to access Jack Demo."
        style={{ marginTop: "16px" }}
      />
      <form onSubmit={registerDemo} style={{ marginTop: "32px" }}>
        <TextFieldJackState
          required
          value={formValues.fullName}
          label="Full Name"
          placeholder="Enter your full legal name"
          onChange={(value) =>
            setFormValues((prev) => ({ ...prev, fullName: value }))
          }
        />
        <TextFieldJackState
          required
          value={formValues.workEmail}
          label="Work Email"
          placeholder="Enter your work email"
          onChange={(value) =>
            setFormValues((prev) => ({ ...prev, workEmail: value }))
          }
        />
        <TextFieldJackState
          required
          value={formValues.businessName}
          label="Business Name"
          placeholder="Enter your business name"
          onChange={(value) =>
            setFormValues((prev) => ({
              ...prev,
              businessName: value,
            }))
          }
        />
        <div
          style={{
            display: "flex",
            gap: "16px",
          }}
        >
          <PhoneSelectionJack
            name="country_code"
            useFormObj={countryCodeForm}
          />
          <TextFieldJackState
            required
            value={formValues.phoneNumber}
            type="number"
            label="Phone Number"
            placeholder="Enter your phone number"
            style={{ width: "100%" }}
            onChange={(value) =>
              setFormValues((prev) => ({
                ...prev,
                phoneNumber: value,
              }))
            }
          />
        </div>
        <ButtonJack
          disabled={isDisabledSendEmail}
          style={{ width: "100%" }}
          onClick={() => {
            eventsTracker(DEMO_CLICK_GET_ACCESS, {
              page_title: "demo_get_access",
            });
          }}
        >
          Verify and get access link
        </ButtonJack>
        <GothamRegular
          style={{
            color: jackColors.neutral900,
            textAlign: "center",
            marginTop: "16px",
          }}
        >
          Already verified?{" "}
          <TextInlineMedium
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              eventsTracker(DEMO_CLICK_RESEND_ACCESS, {
                page_title: "demo_get_access",
              });
              goToResendForm();
            }}
          >
            Resend access link
          </TextInlineMedium>
        </GothamRegular>
      </form>
    </Fragment>
  );
};

export default RegisterForm;
