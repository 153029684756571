import { useEffect, useState } from "react";
import { jackColors } from "../../../../../../assets/colors";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
  TextInlineRegular,
} from "../../../../../../components/Text";
import styles from "./styles.module.css";
import { useSendEmailAndDateTable } from "../../../../hooks";
import { useMutation } from "../../../../../../tools/api";
import { ToasterHook } from "../../../../../../contexts/ToasterContext";
import { RESEND_DELAY_SECOND } from "../../../../constants";
import { eventsTracker } from "../../../../../../universalFunctions/events";
import {
  DEMO_CLICK_GET_ACCESS,
  DEMO_CLICK_RESEND_EMAIL,
} from "../../../../constants/events";

const SentEmailContent = ({
  isLoading = false,
  email: emailRaw = "",
  enableLoading = () => {},
  disableLoading = () => {},
  goToRegisterForm = () => {},
}) => {
  const [email, setEmail] = useState(emailRaw);
  const [resendCount, setResendCount] = useState(RESEND_DELAY_SECOND);

  const { successSnackBar } = ToasterHook();

  const { getSendEmailAndDateTable } = useSendEmailAndDateTable();

  const { mutation: resendEmail, loading: loadingResendEmail } = useMutation({
    method: "post",
    url: "/business_users/resend_confirmation",
    handleError: () => {
      disableLoading();
    },
    afterSuccess: () => {
      setResendCount(RESEND_DELAY_SECOND);
      successSnackBar({ msg: "Email has been resent" });
      disableLoading();
    },
  });

  useEffect(() => {
    if (resendCount <= 0) return;
    const timeout = setTimeout(() => {
      setResendCount((prev) => prev - 1);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [resendCount]);

  // To assign email and count from local storage
  useEffect(() => {
    const sendEmailAndDateTable = getSendEmailAndDateTable();
    if (sendEmailAndDateTable == null) return;
    const foundData = sendEmailAndDateTable[emailRaw];
    const foundEmail = !!foundData ? emailRaw : "";
    const foundEmailDate = !!foundData ? foundData : new Date();
    setEmail(foundEmail);
    const currentTime = new Date().getTime();
    const sendEmailTime = new Date(foundEmailDate).getTime();
    const elapsedTimeInSecond = Math.floor(
      (currentTime - sendEmailTime) / 1000
    );
    const remainingTime = RESEND_DELAY_SECOND - elapsedTimeInSecond;
    if (remainingTime < 0) {
      setResendCount(0);
      return;
    }
    setResendCount(remainingTime);
  }, [emailRaw]);

  const enableResendEmaiLink = resendCount <= 0 && !isLoading;

  const handleResendEmail = () => {
    if (loadingResendEmail) return;
    eventsTracker(DEMO_CLICK_RESEND_EMAIL, {
      page_title: "demo_get_access_success",
    });
    resendEmail({ email });
    enableLoading();
  };

  return (
    <div className={styles.sentEmailContent}>
      <JackIcons
        name="checkmark-circle-2"
        fill={jackColors.neutral900}
        style={{ width: 50, height: 50 }}
        styleDiv={{ width: "fit-content", height: "fit-content" }}
      />
      <GothamMedium className="font24" style={{ marginTop: "32px" }}>
        Check your inbox
      </GothamMedium>
      <GothamRegular
        woFontColor
        style={{
          color: jackColors.neutral700,
          textAlign: "center",
          marginTop: "8px",
        }}
      >
        We've emailed your Jack Demo access link to
        <br />
        <TextInlineRegular style={{ color: jackColors.neutral900 }}>
          {email}
        </TextInlineRegular>
        .
      </GothamRegular>
      <GothamRegular
        woFontColor
        style={{ color: jackColors.neutral700, marginTop: "32px" }}
      >
        Didn't get the email?
      </GothamRegular>
      <GothamRegular woFontColor style={{ color: jackColors.neutral700 }}>
        Check spam folder or{" "}
        {enableResendEmaiLink ? (
          <TextInlineMedium
            style={{ cursor: "pointer" }}
            onClick={handleResendEmail}
          >
            resend email
          </TextInlineMedium>
        ) : (
          <TextInlineMedium>
            resend email {isLoading ? "" : `(${resendCount}s)`}
          </TextInlineMedium>
        )}
      </GothamRegular>
      <GothamRegular
        style={{
          color: jackColors.neutral900,
          position: "absolute",
          bottom: 0,
        }}
      >
        Entered the wrong email?{" "}
        <TextInlineMedium
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => {
            eventsTracker(DEMO_CLICK_GET_ACCESS, {
              page_title: "demo_get_access_success",
            });
            goToRegisterForm();
          }}
        >
          Go back and try again
        </TextInlineMedium>
      </GothamRegular>
    </div>
  );
};

export default SentEmailContent;
