import { Fragment, useEffect, useState } from "react";
import NextImage from "../../../../../../../../components/NextImage";
import {
  GothamMedium,
  GothamRegular,
} from "../../../../../../../../components/Text";
import styles from "./styles.module.css";
import { times } from "lodash";
import ReactPlayer from "react-player";
import { JackIcons } from "../../../../../../../../assets/jackIcons/parent";
import { font12 } from "../../../../../../../virtualAccountComponents/common/utils";
import { eventsTracker } from "../../../../../../../../universalFunctions/events";
import {
  DEMO_MEDIA_VIEW,
  DEMO_PLAY_VIDEO,
} from "../../../../../../constants/events";

const CarouselLighting = () => {
  return (
    <div className={styles.lightingContainer}>
      <NextImage
        src="/images/lime-lighting.svg"
        alt="Lime Lighting"
        width={282}
        height={282}
      />
    </div>
  );
};

const CarouselButton = ({
  isDisabled = false,
  isLeft = false,
  onClick = () => {},
}) => {
  if (isDisabled) {
    return (
      <div
        style={{ width: 20, height: 20, rotate: isLeft ? "0deg" : "180deg" }}
        onClick={onClick}
      >
        <NextImage
          src="/images/carousel-button-disabled.svg"
          alt="Carousel Button Disabled"
          width={20}
          height={20}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        width: 20,
        height: 20,
        rotate: isLeft ? "180deg" : "0deg",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <NextImage
        src="/images/carousel-button-enabled.svg"
        alt="Carousel Button Disabled"
        width={20}
        height={20}
      />
    </div>
  );
};

const Carousel = ({screen = ""}) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [animationClassName, setAnimationClassName] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [showControl, setShowControl] = useState(true);

  const items = [
    {
      title: "International Transfer",
      description: "Send money accross +60 countries effortlessly.",
      url: "/videos/international_transfer-motion.mov",
    },
    {
      title: "Request Reimbursement",
      description: "Submit claims, get approvals, and get paid fast",
      url: "/videos/reimbursement-motion.mov",
    },
    {
      ExpenseArray: [
        "Corporate Card",
        "International Transfer",
        "Local Transfer",
        "Payroll",
        "Bill Payment",
        "Reimbursement",
      ],
      PaymentArray: ["Virtual Account"],
    },
  ];
  const itemsMaxIndex = items.length - 1;

  const handleClickCarouselButton = (isGoRight = true) => {
    if (isGoRight && carouselIndex < itemsMaxIndex) {
      if (carouselIndex === 0) {
        setAnimationClassName(styles.swipeLeftOnZeroIndex);
      }
      if (carouselIndex === 1) {
        setAnimationClassName(styles.swipeLeftOnFirstIndex);
      }
      setCarouselIndex((prev) => prev + 1);
      return;
    }
    if (!isGoRight && carouselIndex > 0) {
      if (carouselIndex === 2) {
        setAnimationClassName(styles.swipeRightOnSecondIndex);
      }
      if (carouselIndex === 1) {
        setAnimationClassName(styles.swipeRightOnFirstIndex);
      }
      setCarouselIndex((prev) => prev - 1);
      return;
    }
    return;
  };

  useEffect(() => {
    if (isPlaying) {
      return setShowControl(false);
    }
    return setShowControl(true);
  }, [isPlaying]);

  const media_type = (function () {
    switch (carouselIndex) {
      case 0:
        return "international_transfer";
      case 1:
        return "reimbursement";
      case 2:
        return "general_info";
      default:
        return "";
    }
  })();

  useEffect(() => {
    eventsTracker(DEMO_MEDIA_VIEW, {
      page_title: screen,
      media_type,
    });
  }, [carouselIndex]);

  return (
    <div className={styles.carouselContainer}>
      <CarouselLighting />

      <div className={styles.contentContainer}>
        <div className={styles.carouselTitleContainer}>
          <GothamMedium className="font20" style={{ color: "white" }}>
            Jack's Products and Solutions
          </GothamMedium>
          <div className={styles.carouselNavContainer}>
            <div className={styles.carouselDotContainer}>
              {times(items.length).map((_, index) => {
                const isSelected = carouselIndex === index;
                return (
                  <div
                    className={styles.carouselDot}
                    style={{ opacity: isSelected ? 1 : 0.25 }}
                  />
                );
              })}
            </div>
            <div className={styles.carouselButtonsContainer}>
              <CarouselButton
                isLeft
                isDisabled={carouselIndex <= 0}
                onClick={() => {
                  handleClickCarouselButton(false);
                }}
              />
              <CarouselButton
                isDisabled={carouselIndex >= itemsMaxIndex}
                onClick={() => {
                  handleClickCarouselButton(true);
                }}
              />
            </div>
          </div>
        </div>
        <div className={`${styles.slidesContainer} ${animationClassName}`}>
          {items.map((item, index) => {
            const playing = index === carouselIndex;
            const {
              url = "",
              title = "",
              description = "",
              PaymentArray = [],
              ExpenseArray = [],
            } = item ?? {};
            const isFirstIndex = index === 0;
            if (!!url)
              return (
                <div
                  className={styles.slide}
                  key={index}
                  onMouseEnter={() => setShowControl(true)}
                >
                  <ReactPlayer
                    loop
                    muted
                    playing={isPlaying}
                    width="100%"
                    height="100%"
                    url={url}
                    style={{ overflow: "hidden", borderRadius: "8px" }}
                  />
                  {showControl && (
                    <Fragment>
                      <button
                        onClick={() => {
                          setIsPlaying((prev) => !prev);
                          if (!isPlaying) {
                            eventsTracker(DEMO_PLAY_VIDEO, {
                              page_title: screen,
                              video_type: isFirstIndex
                                ? "international_transfer"
                                : "reimbursement",
                            });
                          }
                        }}
                        className={styles["play-button"]}
                        style={{ left: isFirstIndex ? "50%" : "155%" }}
                      >
                        <JackIcons
                          name="PlayCircle"
                          fill="white"
                          style={{ height: "24px" }}
                        />
                      </button>
                      <div className={styles["slide-caption"]}>
                        <GothamMedium style={{ ...font12, color: "white" }}>
                          {title}
                        </GothamMedium>
                        <GothamRegular style={{ ...font12, color: "white" }}>
                          {description}
                        </GothamRegular>
                      </div>
                    </Fragment>
                  )}
                </div>
              );
            return (
              <div className={styles["last-slide"]} key={index}>
                <GothamMedium
                  style={{
                    color: "white",
                    marginBottom: "16px",
                  }}
                >
                  Expense Management
                </GothamMedium>
                <div className={styles["last-slide-text-container"]}>
                  {ExpenseArray?.map((data, idx) => {
                    return (
                      <div key={idx} className={styles["last-slide-text"]}>
                        <JackIcons
                          name={"CheckmarkCircle2Outline"}
                          fill="white"
                          style={{ height: "16px" }}
                        />
                        <GothamRegular
                          style={{
                            color: "white",
                          }}
                        >
                          {data}
                        </GothamRegular>
                      </div>
                    );
                  })}
                </div>
                <GothamMedium
                  style={{
                    color: "white",
                    margin: "16px 0",
                  }}
                >
                  Payment Collection
                </GothamMedium>
                {PaymentArray?.map((data, idx) => {
                  return (
                    <div key={idx} className={styles["last-slide-text"]}>
                      <JackIcons
                        name={"CheckmarkCircle2Outline"}
                        fill="white"
                        style={{ height: "16px" }}
                      />
                      <GothamRegular
                        style={{
                          color: "white",
                        }}
                      >
                        {data}
                      </GothamRegular>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
