import React from "react";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { jackColors } from "../../../assets/colors";

export const PopupComponent = ({
  children,
  isOpen,
  toggle,
  toggleModal,
  type = "black",
  style,
}) => (
  <div
    style={{
      position: "fixed",
      right: isOpen ? 20 : -220,
      bottom: 20,
      width: 208,
      backgroundColor: type == "black" ? "black" : jackColors.greyE6,
      padding: 12,
      borderRadius: 4,
      flexDirection: "column",
      transition: "all 0.4s linear",
      zIndex: 2,
      ...style,
    }}
    className="hover d-flex"
    onClick={toggleModal}
  >
    <JackIcons
      name="close"
      fill={type == "black" ? "white" : "black"}
      styleDiv={{
        position: "absolute",
        right: 8,
        top: 8,
        width: 20,
      }}
      className="hover"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        toggle();
      }}
    />
    {children}
  </div>
);
