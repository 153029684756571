import { useEffect, useState } from "react";
import FullScreenLoading from "../../../../components/FullScreenLoading";
import BlockingBlurModal from "../../../../modals/BlockingBlurModal";
import { eventsTracker } from "../../../../universalFunctions/events";
import { DEMO_PAGE_VIEW } from "../../constants/events";
import { useDemoLogin, useRegisterDemoForm } from "../../hooks";
import {
  RegisterForm,
  ResendForm,
  SentEmailContent,
  SideBannerContent,
} from "./components";
import styles from "./styles.module.css";
import { TurnstilePage } from "../TurnstilePage";

const RegisterDemoModal = ({ isOpen = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [verifyToken, setVerifyToken] = useState("");
  const [screen, setScreen] = useState("register-form"); // register-form | resend-form | success
  const session_id = localStorage.getItem("session_id")

  const enableLoading = () => setIsLoading(true);
  const disableLoading = () => setIsLoading(false);

  const goToRegisterForm = () => setScreen("register-form");
  const goToResendForm = () => setScreen("resend-form");
  const goToSuccess = () => setScreen("success");

  // This hook detects email & password on query
  useDemoLogin({
    isLoading,
    enableLoading,
    disableLoading,
    verifyToken,
    session_id,
  });

  const {
    formValues,
    countryCodeForm,
    isValidFormValues,
    isLoadingRegisterDemo,
    registerDemo,
    setFormValues,
  } = useRegisterDemoForm({
    enableLoading,
    disableLoading,
    goToSuccess,
    verifyToken,
    session_id,
  });

  const showLoading = isLoading || isLoadingRegisterDemo;
  const isDisabledSendEmail = !isValidFormValues || showLoading;

  const content = () => {
    switch (screen) {
      case "register-form":
        return (
          <RegisterForm
            formValues={formValues}
            countryCodeForm={countryCodeForm}
            isDisabledSendEmail={isDisabledSendEmail}
            registerDemo={registerDemo}
            setFormValues={setFormValues}
            goToResendForm={goToResendForm}
          />
        );

      case "resend-form":
        return (
          <ResendForm
            isLoading={isLoading}
            formValues={formValues}
            goToSuccess={goToSuccess}
            setFormValues={setFormValues}
            enableLoading={enableLoading}
            disableLoading={disableLoading}
            goToRegisterForm={goToRegisterForm}
          />
        );

      case "success":
        return (
          <SentEmailContent
            isLoading={isLoading}
            email={formValues.workEmail}
            enableLoading={enableLoading}
            disableLoading={disableLoading}
            goToRegisterForm={goToRegisterForm}
          />
        );

      default:
        return null;
    }
  };

  const page_title = (function () {
    switch (screen) {
      case "register-form":
        return "demo_get_access";
      case "resend-form":
        return "demo_resend_access";
      case "success":
        return "demo_get_access_success";
      default:
        return null;
    }
  })();

  useEffect(() => {
    eventsTracker(DEMO_PAGE_VIEW, { page_title });
  }, [screen, page_title]);

  return (
    <BlockingBlurModal isOpen={isOpen}>
      <TurnstilePage setVerifyToken={setVerifyToken}>
        <div className={styles.registerDemoModal}>
          <div className={styles.leftContainer}>
            <SideBannerContent screen={page_title} />
          </div>
          <div className={styles.rightContainer}>
            <div
              className={`${styles.registerDemoForm} ${styles.fadeInTop}`}
              style={{ height: screen === "resend-form" ? "372px" : "660px" }}
            >
              {content()}
            </div>
          </div>
        </div>

        <FullScreenLoading show={showLoading} color="black" />
      </TurnstilePage>
    </BlockingBlurModal>
  );
};

export default RegisterDemoModal;
