import { Fragment } from "react";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../../../../components/Text";
import { Banner } from "../../../../../../components/Banner";
import { ButtonJack } from "../../../../../../components/ButtonsJack/parent";
import { jackColors } from "../../../../../../assets/colors";
import { useMutation } from "../../../../../../tools/api";
import { ToasterHook } from "../../../../../../contexts/ToasterContext";
import { TextFieldJackState } from "../../../../../../components/inputs/textfield";
import { useSendEmailAndDateTable } from "../../../../hooks";
import { RESEND_DELAY_SECOND } from "../../../../constants";
import {
  DEMO_CLICK_GET_ACCESS,
  DEMO_CLICK_RESEND_ACCESS,
} from "../../../../constants/events";
import { eventsTracker } from "../../../../../../universalFunctions/events";

const ResendForm = ({
  isLoading = false,
  formValues = {},
  goToSuccess = () => {},
  setFormValues = () => {},
  enableLoading = () => {},
  disableLoading = () => {},
  goToRegisterForm = () => {},
}) => {
  const { getSendEmailAndDateTable, storeSendEmailAndDateTable } =
    useSendEmailAndDateTable();

  const { successSnackBar } = ToasterHook();

  const { mutation: resendEmail, loading: loadingResendEmail } = useMutation({
    method: "post",
    url: "/business_users/resend_confirmation",
    handleError: () => {
      disableLoading();
    },
    afterSuccess: () => {
      storeSendEmailAndDateTable(formValues.workEmail);
      successSnackBar({ msg: "Email has been resent" });
      goToSuccess();
      disableLoading();
    },
  });

  const handleResendEmail = (event) => {
    event.preventDefault();
    if (loadingResendEmail) return;
    const sendEmailAndDateTable = getSendEmailAndDateTable();
    if (sendEmailAndDateTable != null) {
      const foundData = sendEmailAndDateTable[formValues.workEmail];
      if (foundData) {
        const foundEmailDate = !!foundData ? foundData : new Date();
        const currentTime = new Date().getTime();
        const sendEmailTime = new Date(foundEmailDate).getTime();
        const elapsedTimeInSecond = Math.floor(
          (currentTime - sendEmailTime) / 1000
        );
        const remainingTime = RESEND_DELAY_SECOND - elapsedTimeInSecond;
        if (remainingTime < 0) {
          resendEmail({ email: formValues.workEmail });
          enableLoading();
          return;
        }
        goToSuccess();
        return;
      }
    }
    resendEmail({ email: formValues.workEmail });
    enableLoading();
  };

  const isDisabledResendEmail =
    formValues.workEmail.length <= 0 || isLoading || loadingResendEmail;

  return (
    <Fragment>
      <GothamMedium
        className="font24"
        style={{ color: jackColors.neutral900, textAlign: "center" }}
      >
        Resend access link
      </GothamMedium>
      <Banner
        msg="Input your verified email and we'll resend the Jack Demo access link."
        style={{ marginTop: "16px" }}
      />
      <form onSubmit={handleResendEmail} style={{ marginTop: "32px" }}>
        <TextFieldJackState
          required
          value={formValues.workEmail}
          label="Work Email"
          placeholder="Enter your work email"
          onChange={(value) =>
            setFormValues((prev) => ({ ...prev, workEmail: value }))
          }
        />
        <ButtonJack
          disabled={isDisabledResendEmail}
          style={{ width: "100%" }}
          onClick={() => {
            eventsTracker(DEMO_CLICK_RESEND_ACCESS, {
              page_title: "demo_resend_access",
            });
          }}
        >
          Get access link
        </ButtonJack>
        <GothamRegular
          style={{
            color: jackColors.neutral900,
            textAlign: "center",
            marginTop: "16px",
          }}
        >
          Haven't accessed it before?{" "}
          <TextInlineMedium
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              goToRegisterForm();
              eventsTracker(DEMO_CLICK_GET_ACCESS, {
                page_title: "demo_resend_access",
              });
            }}
          >
            Verify email
          </TextInlineMedium>
        </GothamRegular>
      </form>
    </Fragment>
  );
};

export default ResendForm;
