import React, { useEffect } from "react";
import { useHeader } from "../contexts/Layout/parent";
import { LoginPage } from "../pageComponents/loginPageComponents/parent";
import RegisterDemoModal from "../pageComponents/productDemoComponents/components/RegisterDemoModal";
import { DashboardPage } from "../pageComponents/dashboardComponents/parent";

const Login = () => {
  const { setHeader } = useHeader();

  useEffect(() => setHeader({}), []);

  return (
    <div>
      <DashboardPage />
      <RegisterDemoModal isOpen />
    </div>
  );

  return <LoginPage />;
};

export default Login;
