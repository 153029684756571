import { jackColors } from "../../../../../../assets/colors";
import NextImage from "../../../../../../components/NextImage";
import { GothamMedium, GothamRegular } from "../../../../../../components/Text";
import { Carousel, FooterTags } from "./components";
import styles from "./styles.module.css";

const SideBannerContent = ({screen = ""}) => {
  return (
    <div className={`${styles.sideBannerContent} ${styles.slideInRight}`}>
      <div>
        <div className={styles.logoContainer}>
          <NextImage
            src="/images/jack-logo-white.png"
            alt="Jack Logo"
            width={103}
            height={48}
          />
          <div className={styles.demoTag}>
            <GothamMedium style={{ color: jackColors.neutral900 }}>
              Demo
            </GothamMedium>
          </div>
        </div>
        <GothamMedium
          className="font28"
          style={{ color: jackColors.neutral100, marginTop: "64px" }}
        >
          Welcome to Jack Demo
        </GothamMedium>
        <GothamRegular
          woFontColor
          style={{ color: jackColors.neutral500, marginTop: "16px" }}
        >
          Try out Jack’s features with this hands-on demo and see how it can
          elevate your business operations.
        </GothamRegular>
      </div>

      <div>
        <Carousel screen={screen} />
        <FooterTags />
      </div>
    </div>
  );
};

export default SideBannerContent;
