import { jackColors } from "../../../assets/colors";
import { TextInlineRegular } from "../../../components/Text";
import { CheckEmailComponent } from "./component";

const CheckEmailPageComponents = ({
  type,
  emailSent,
  setCurrentScreen,
  mutationForgotPassword,
  mutationResendRegister,
}) => (
  <CheckEmailComponent
    title="We’ve sent you the link!"
    subTitle={
      <>
        Click the link we’ve sent to{" "}
        <TextInlineRegular style={{ color: jackColors.black34 }}>
          {emailSent}
        </TextInlineRegular>{" "}
        {type === "checkEmailRegister"
          ? "and follow the next instruction to activate your account."
          : "to reset your password."}
      </>
    }
    instruction="Didn’t get the email?"
    sendAgainInfo="Check spam folder or"
    sendAgainText="get a new link"
    setCurrentScreen={setCurrentScreen}
    onSendAgain={async (afterSuccessSendAgain) => {
      const isRegister = type === "checkEmailRegister";
      if (isRegister) await mutationResendRegister({ email: emailSent });
      else await mutationForgotPassword({ email: emailSent });
      afterSuccessSendAgain();
    }}
  />
);

export default CheckEmailPageComponents;
